<template>
  <div class="invite">
    <common-header title="分享好友" />
    <refer-link />
    <inviet-table />
  </div>
</template>

<script>
import CommonHeader from "../../components/CommonHeader.vue";
import InvietTable from "./InvietTable.vue";
import ReferLink from "../Home/ReferLink.vue";
export default {
  components: { CommonHeader, ReferLink, InvietTable },
  name: "Invite",
};
</script>

<style lang="less" scoped>
.invite {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 30px;
  .invite-sign {
    width: 100%;
    height: 139px;
    flex-shrink: 0;
  }
}
</style>
