<template>
  <div class="invite-table">
    <h4>邀请明细</h4>
    <van-list
      class="invite-list"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="table-title">
        <p class="time">时间</p>
        <p class="address">地址</p>
      </div>
      <div class="table-title-item" v-for="item in list" :key="item._id">
        <p class="time">{{ item.createdAt }}</p>
        <p class="address">
          {{ item.address.slice(0, 10) + "***" + item.address.slice(-10) }}
        </p>
      </div>
    </van-list>
  </div>
</template>

<script>
import { recommendUsers } from "../../server/index";
import * as timeago from "timeago.js";

export default {
  name: "InviteTable",

  data() {
    return {
      list: [],
      loading: false,
      finished: true,
    };
  },
  methods: {
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }

        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
  },
  async created() {
    const data = await recommendUsers(this.getAddress);
    this.list = data.list.map((item) => {
      return {
        ...item,
        createdAt: timeago.format(item.createdAt, "zh_CN"),
      };
    });
  },
};
</script>

<style lang="less" scoped>
.invite-table {
  width: 345px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 30px;

  h4 {
    box-sizing: border-box;
    padding-top: 20px;
    padding-bottom: 15px;
    font-size: 16px;
    font-family: "PingFang SC";
    font-weight: bold;
    color: #e9ecf0;
  }

  .invite-list {
    margin-bottom: 30px;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    width: 345px;
    height: 306px;
    background: #161f2c;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.09);
    opacity: 1;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;

    overflow-y: scroll;

    .table-title,
    .table-title-item {
      flex-shrink: 0;
      width: 100%;
      height: 37px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #263243;
      font-size: 12px;
      font-family: "PingFang SC";
      font-weight: 400;
      color: #c1c1c1;

      .time {
        width: 152px;
      }

      .address {
        flex-grow: 1;
      }
    }

    .table-title-item {
      .time {
        color: #019969;
      }

      .address {
        color: #e9ecf0;
      }
    }
  }
}
</style>
